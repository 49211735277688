import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Box from 'components/box';
import Head from 'components/head';

const Partners = ({ data }) => (
  <Layout>
    <Head pageTitle={data.partnersJson.title} />
    <Box>
      <div
        dangerouslySetInnerHTML={{
          __html: data.partnersJson.content.childMarkdownRemark.html,
        }}
      />
    </Box>
    <Box>
      <div className="iframe-wrapper">
        <iframe
          className="partner-form"
          title="partner form"
          height="3800"
          src="https://docs.google.com/forms/d/e/1FAIpQLSfcMMGF8ezlWRMbFrIx708g8uXtWmpUDmdNwd2Jzl7QuCaHoQ/viewform"
        />
      </div>
    </Box>
  </Layout>
);

Partners.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Partners;

export const query = graphql`
  query PartnersQuery {
    partnersJson {
      title
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
